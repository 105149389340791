import { Controller } from "@hotwired/stimulus"
import StackManager from "models/stack_manager"

// This acts as the high-level controller for the sheet stack
// to intercept the openining of dialog (or action-sheet) and push it to the stack
class SheetStack extends Controller {
  initialize() {
    this._dialogOpenedBind = this.openedDialog.bind(this)
  }

  connect() {
    window.addEventListener("sheet-stack-dialog:present", this._dialogOpenedBind)
    window.addEventListener("sheet-stack-action-sheet:present", this._dialogOpenedBind)
  }

  disconnect() {
    window.removeEventListener("sheet-stack-dialog:present", this._dialogOpenedBind)
    window.removeEventListener("sheet-stack-action-sheet:present", this._dialogOpenedBind)
  }

  openedDialog(event) {
    const currentDialog = event.detail.targetDialog
    if (!currentDialog) return

    const stackManager = this._stackManagerFor(currentDialog)
    this._appendSheetController(currentDialog, stackManager)
    stackManager.push(currentDialog)
  }

  _appendSheetController(dialog, stackManager) {
    let dataControllers = dialog.dataset.controller || ""

    // We need to specifically check for sheet controller to prevent duplication
    if (dataControllers.split(" ").includes("sheet")) return

    dataControllers = `${dataControllers} sheet`
    dialog.dataset.sheetStackIndexValue = stackManager.length + 1
    dialog.dataset.controller = dataControllers
  }

  _stackManagerFor(dialog) {
    let targetStackName = dialog.dataset.dialogStackNameValue
    if (!targetStackName) {
      targetStackName = dialog.dataset.actionSheetStackNameValue
    }
    return StackManager.loadFor(targetStackName)
  }
}

export default SheetStack
