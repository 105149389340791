import { Controller } from "@hotwired/stimulus"
import StackManager from "models/stack_manager"
import BreakpointChange from "shared/breakpoint_change"

class Sheet extends Controller {
  static targets = [
    "desktopCloseButton",
    "mobileCloseButton",
    "closeButtonHolder"
  ]

  connect() {
    this.element.addEventListener("sheet-update:pop", this.onPop.bind(this))
    this._stackManager = this._stackManagerFor(this.element)
    this._buildCloseButtons()
    this._attachBreakpointListeners()
  }

  disconnect() {
    this.element.removeEventListener("sheet-update:pop", this.onPop.bind(this))
  }

  pop(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    this._stackManager.pop()
  }

  close(event) {
    event.preventDefault()

    // TODO: Change this once we have a shared context between all dialogs
    this._closeStack = true
    this._stackManager.clear()
  }

  onPop(event) {
    event.preventDefault()

    this._closeDialog(this.element)
  }

  _closeDialog(dialog) {
    if (!dialog) return

    const dialogCtrl = this._loadControllerFor(dialog)
    if (dialogCtrl) {
      dialogCtrl.close({
        detail: { dialogCloseClassName: this.dialogCloseClassName }
      })
    }
  }

  _loadControllerFor(dialog) {
    let dialogCtrl = this.application.getControllerForElementAndIdentifier(dialog, "dialog")
    if (!dialogCtrl) {
      // We need to fallback to action-sheet
      dialogCtrl = this.application.getControllerForElementAndIdentifier(dialog, "action-sheet")
    }
    return dialogCtrl
  }

  _stackManagerFor(dialog) {
    let targetStackName = dialog.dataset.dialogStackNameValue
    if (!targetStackName) {
      targetStackName = dialog.dataset.actionSheetStackNameValue
    }
    return StackManager.loadFor(targetStackName)
  }

  _buildCloseButtons() {
    if (this.hasCloseButtonHolderTarget) {
      this._desktopCloseButton = this.desktopCloseButtonTarget.content.firstElementChild.cloneNode(true)
      this._mobileCloseButton = this.mobileCloseButtonTarget.content.firstElementChild.cloneNode(true)
      this.closeButtonHolderTarget.appendChild(this._desktopCloseButton)
      this.closeButtonHolderTarget.appendChild(this._mobileCloseButton)
    }
  }

  _attachBreakpointListeners() {
    const breakpointChange = BreakpointChange.buildFromGlobal()
    breakpointChange.triggerOnAddition = true

    // on tiny, small breakpoints, we show the mobile close button
    breakpointChange.on(["tiny", "small"], (mediaQueryMatches) => {
      if (mediaQueryMatches) {
        this._showMobileCloseButton()
      }
    })

    breakpointChange.on(["medium", "large", "huge"], (mediaQueryMatches) => {
      if (mediaQueryMatches) {
        this._showDesktopCloseButton()
      }
    })
  }

  _showMobileCloseButton() {
    if (!this.hasCloseButtonHolderTarget) {
      return
    }

    this._hideCloseButtons()
    this._mobileCloseButton.style.display = ""
  }

  _showDesktopCloseButton() {
    if (!this.hasCloseButtonHolderTarget) {
      return
    }

    this._hideCloseButtons()
    this._desktopCloseButton.style.display = ""
  }

  _hideCloseButtons() {
    this.closeButtonHolderTarget.querySelectorAll("a.button").forEach((button) => {
      button.style.display = "none"
    })
  }

  get dialogCloseClassName() {
    if (this._closeStack) {
      return "dialog--closing-stack"
    } else {
      return "dialog--closing"
    }
  }
}

export default Sheet
